<template>
  <div>
    <div class="user">
      <a-avatar :size="64" :src="user.head" icon="user"> </a-avatar>
      <div class="user-name">{{ user.name }}</div>
      <div class="other-info">
        <div v-if="user.mobile && user.mobile.length>0">
          <a-icon type="mobile" />： <span>{{ user.mobile }}</span>
        </div>
        <div v-else-if="user.nickName && user.nickName.length>0">
          <a-icon type="smile" />： <span>{{ user.nickName }}</span>
        </div>
      </div>
    </div>
    <div class="menu">
      <dl v-for="(item, index) in menu" :key="index">
        <dt>{{ item.title }}</dt>
        <dd v-for="(item, index) in item.list" :key="index">
          <a :data-action="item.action" @click="go(item.action)">{{
            item.title
          }}</a>
        </dd>
      </dl>
    </div>
</div>
</template>

<script>

export default {
  data() {
    return {
      menu: [
        {
          title: "个人中心",
          list: [
            {
              title: "首页",
              action: "/user",
            },
            {
              title: "基础信息",
              action: "/user/Info",
            },
            {
              title: "我的余额",
              action: "/user/Blances",
            },
            {
              title: "我的积分",
              action: "/user/Bonuspoints",
            },
            {
              title: "我的礼券",
              action: "/user/Coupons",
            },
            {
              title: "地址簿",
              action: "/user/Addressbook",
            },
          ],
        },
        {
          title: "订单中心",
          list: [
            {
              title: "我寄的",
              action: "/user/Mypost",
            },
            {
              title: "我收的",
              action: "/user/Myreceive",
            },
            {
              title: "商城订单",
              action: "/user/Myorder",
            },
            {
              title: "退出",
              action: "lgout",
            },
          ],
        },
      ],
      user: {},
    };
  },
  mounted() {
    this.user = this.storage.getItem("user");
    if (!this.user) {
      this.getUserInfo();
    }
  },
  methods: {
    getUserInfo() {
      this.api
        .getuserDetail({})
        .then((res) => {
          this.storage.setItem("user", res);
          this.user = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    go(e) {
      if (e == "lgout") {
        this.storage.setItem("token", null);
        this.storage.setItem("user", null);
        this.$router.push("/");
      } else {
        this.$router.push(e);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "~ant-design-vue/dist/antd.less";

.menu {
  dt {
    font-weight: bold;
  }

  dd a {
    color: #666;
  }

  dd a:hover {
    color: #f8ce09;
  }
}

.waybill-list {
  // padding: 15px;

  .ant-card-bordered {
    border-radius: 5px;
  }

  .state {
    padding-top: 10px;
    padding-bottom: 8px;
  }

  .time {
    padding-top: 5px;
    justify-content: space-between;

    .timeitem {
      color: #c8c9cc;
    }

    .price {
      color: #cf0022;
      font-size: 15px;
      font-weight: 600;
    }
  }
}

.waybill-info {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .city {
    font-size: 16px;
    font-weight: 600;
    line-height: 2;
  }

  .user {
    font-size: 14px;
    color: @text-color-secondary;
  }

  div {
    text-align: center;
  }
}
</style>